import {
    Component, OnDestroy, OnInit
} from '@angular/core';
import { LoginService } from 'src/app/pages/login/login.service';
import { UsersService } from 'src/app/pages/users/users.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { UseTermsService } from '../../use-terms.service';

@Component({
    selector: 'app-modal-policy-and-privacy',
    templateUrl: './modal-policy-and-privacy.component.html',
    styleUrls: ['./modal-policy-and-privacy.component.scss']
})
export class ModalPolicyAndPrivacyComponent implements OnInit, OnDestroy {
    onDestroyOpenModal$: any;
    onDestroyAuthObject$: any;
    onDestroyPrivacyTerms$: any;
    isVisible = false;
    isCheckedPolicyAndPrivacy = false;
    policyAndPrivacyText = '';
    isTermsMandatory = false;
    isPolicyMandatory = true;
    useTermsText = '';
    i18n: any = [];
    userAuth: any;
    buttonLeft = '';
    buttonRight = '';
    readOnly = false;
    userName = '';
    user: any;
    msgAcceptedDate = '';
    userLoggedIn: boolean;

    constructor(
        private loginService: LoginService,
        private useTermsService: UseTermsService,
        private sharedService: SharedService,
        public platformModalsService: PlatformModalsService,
        private localStorageService: LocalStorageService,
        private usersService: UsersService,
    ) { }

    ngOnInit(): void {
        this.checkTranslations();
        this.isUserLoggedIn();

        this.onDestroyOpenModal$ = this.loginService.openModalPolicyAndPrivacy$.subscribe({
            next: (response) => {
                if (response.readOnly) {
                    this.readOnly = true;
                    this.isCheckedPolicyAndPrivacy = true;
                    this.getDateAcepted();
                } else {
                    this.readOnly = false;
                }

                if (response) {
                    this.buttonLeft = this.i18n.policy_privacy_continue;
                    this.buttonRight = this.i18n.policy_privacy_cancel;

                    if (this.readOnly) {
                        this.buttonRight = this.i18n.policy_privacy_close;
                    }
                }

                if (response.openModal) {
                    this.getUseTerms();
                }
            },
        });
        this.onDestroyAuthObject$ = this.loginService.authObject$.subscribe({
            next: (response: any) => {
                this.userAuth = response;
            }
        });
    }

    checkTranslations(): void {
        const interval = setInterval(() => {
            if (localStorage.getItem('translations') !== null) {
                clearInterval(interval);
                this.getTranslations();
            }
        }, 200);
    }

    getTranslations(): void {
        this.i18n = { ...this.sharedService.getTranslationsOf('Errors'), ...this.sharedService.getTranslationsOf('PolicyAndPrivacy') };
    }

    isUserLoggedIn(): void {
        this.sharedService.isLoggedIn.subscribe({
            next: (response) => {
                this.userLoggedIn = response;
            }
        });
    }

    getUseTerms(): void {
        if (this.userLoggedIn) {
            this.useTermsService.getUseTermsWithToken()
                .subscribe({
                    next: (response) => {
                        this.useTermsText = response.terms_use;
                        this.isTermsMandatory = response.terms_mandatory;
                        this.policyAndPrivacyText = response.terms_policy;
                        // Se o termos de uso for mandatório, o usuário precisará clicar no botão Li e Concordo para habilitar o botão
                        // Se não, o botão já fica habilitado de padrão
                        if (!this.isTermsMandatory) {
                            this.isCheckedPolicyAndPrivacy = true;
                        }
                        this.isVisible = true;
                    },
                });
        } else {
            localStorage.removeItem('token');
            this.useTermsService.getUseTermsNoToken()
                .subscribe({
                    next: (response) => {
                        this.useTermsText = response.terms_use;
                        this.isTermsMandatory = response.terms_mandatory;
                        this.policyAndPrivacyText = response.terms_policy;
                        // Se o termos de uso for mandatório, o usuário precisará clicar no botão Li e Concordo para habilitar o botão
                        // Se não, o botão já fica habilitado de padrão

                        this.isVisible = true;
                    },
                });
        }
    }

    acceptPolicyAndPrivacy(): void {
        const options = {
            token: this.userAuth.token
        };

        this.useTermsService.acceptPolicyAndPrivacy(options)
            .subscribe({
                next: () => {
                    this.acceptUseTerms();
                    this.sharedService.loggedIn.next(true);
                    this.sharedService.storeUserCredentials(this.userAuth);
                    this.loginService.setGoogleCredencials(this.userAuth);
                    this.loginService.isRedirectRoute();
                },
                error: () => {
                    this.platformModalsService.toggle('message', this.i18n.error_system_error, 'close');
                }
            });
    }

    acceptUseTerms() {
        const options = {
            token: this.userAuth.token
        };

        this.useTermsService.acceptUseTerms(options)
            .subscribe({
                next: () => {
                    this.sharedService.loggedIn.next(true);
                    this.sharedService.storeUserCredentials(this.userAuth);
                    this.loginService.setGoogleCredencials(this.userAuth);
                    this.loginService.isRedirectRoute();
                },
                error: () => {
                    this.platformModalsService.toggle('message', this.i18n.error_system_error, 'close');
                }
            });
    }

    closeModalAndLoggin(): void {
        this.sharedService.loggedIn.next(true);
        this.sharedService.storeUserCredentials(this.userAuth);
        this.closeModal();
    }

    closeModalAndContinue(): void {
        this.closeModal();
    }

    receiveCheckButton(event: any): void {
        this.isCheckedPolicyAndPrivacy = event;
    }

    closeModal(): void {
        if (!this.readOnly) {
            this.sharedService.deleteKeyLocalStorage('token');
            this.sharedService.deleteKeyLocalStorage('permissions');
        }

        this.isVisible = false;
        this.isCheckedPolicyAndPrivacy = false;
        this.policyAndPrivacyText = '';
    }

    ngOnDestroy(): void {
        if (this.onDestroyOpenModal$) this.onDestroyOpenModal$.unsubscribe();
        if (this.onDestroyAuthObject$) this.onDestroyAuthObject$.unsubscribe();
    }

    continueToUseTerms(): void {
        if (this.isTermsMandatory || this.readOnly) {
            this.loginService.openModalUseTerms$.next({
                openModal: true, readOnly: this.readOnly, terms_policy: this.useTermsText, terms_mandatory: this.isTermsMandatory
            });

            this.closeModal();
            return;
        }

        this.acceptPolicyAndPrivacy();
    }

    getDatesAccepted($externalId: any): void {
        this.usersService.getUsersAcceptedTerms($externalId)
            .subscribe({

                next: (response) => {
                    let currentLanguage;

                    if (this.localStorageService.getLocalStorageItem('currentLanguage')) {
                        currentLanguage = localStorage.getItem('currentLanguage');
                    }

                    if (!currentLanguage) {
                        currentLanguage = 'pt-BR';
                    }

                    if (response?.terms_policy_agreement_date) {
                        const newDate = new Date(response?.terms_policy_agreement_date).toLocaleDateString(currentLanguage, {
                            year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
                        });

                        this.msgAcceptedDate = `${this.i18n.use_terms_accept} ${newDate} ${this.i18n.use_terms_by} ${this.userName}`;
                    }
                }
            });
    }

    getDateAcepted(): void {
        this.sharedService
            .getUser()
            .subscribe({
                next: (user: any) => {
                    if (user) {
                        this.user = user;
                        this.userName = user.name;

                        if (this.user?.external_id) {
                            this.getDatesAccepted(this.user.external_id);
                        }
                    }
                }
            });
    }
}
