import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
    ChangeDetectorRef,
    Component, ElementRef, HostListener, OnInit, QueryList, SecurityContext, ViewChildren
} from '@angular/core';
import {
    FormArray, FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MessengerToastBaseService } from 'src/app/components/messenger-toast/messenger-toast-base/messenger-toast-base.service';
import { ListItem, PostDetailedPlanning, Resources } from 'src/app/pages/content-planner/interfaces/detailed-planning.interface';
import { GroupedClasses, ResponseModules } from 'src/app/pages/content-planner/interfaces/modules.interface';
import { ContentPlannerService } from 'src/app/pages/content-planner/services/content-planner.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-detailed-class-to-class',
    templateUrl: './detailed-class-to-class.component.html',
    styleUrls: ['./detailed-class-to-class.component.scss']
})
export class DetailedClassToClassComponent implements OnInit {
    @ViewChildren('multiSelectElement') multiSelectElements: QueryList<ElementRef> | undefined;
    resourcesList: Resources[] = [];
    activitiesList: Resources[] = [];
    modulesList: ResponseModules[] = [];
    materialsList: ListItem[] = [];
    proceduresList: ListItem[] = [];
    classList: [] = [];
    planningExternalId = '';
    planningDetail: any = {};
    type: 'class_to_class' | 'estimated' | '' = '';
    form: FormGroup;
    isVisibleSuccessPlanningModal = false;
    i18n: any = {};
    isExpanded: boolean[] = [];
    isExpandedClass: boolean[] = [];
    isExpandedClassWeek: boolean[] = [];
    isVisibleResourceModal = false;
    moduleResources: any = [];
    classIdResource: any;
    classResources: any = [];
    contentTypes: any = {};
    isVisibleModalLoading = false;
    messageModalLoading = '';
    currentLenguage = '';
    selectedWeeks: { [classId: number]: { week: number } } = {};
    activePlanningWeek = false;
    currentDragIndex: number | null = null;

    weeks = Array.from({ length: 40 }, (_, index) => ({
        id: index + 1,
    }));

    groupedClasses: GroupedClasses = {};
    isEmptyGroupedClasses = true;

    selectedModeOrganization: string;
    selectedMethodologies: { [moduleId: number]: number | null } = {};
    selectedSteps: { [classId: number]: number[] } = {};

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private contentPlannerService: ContentPlannerService,
        private platformModalService: PlatformModalsService,
        private sharedService: SharedService,
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef,
        private toastBaseMessenger: MessengerToastBaseService,
    ) {
        this.createFormModule();
    }

    ngOnInit(): void {
        this.getTraductions();
        this.getParamsFromRoute();
        this.getResources();
        this.getModules();
        this.getClassSupportItems();
        this.getPlanning();
        this.listeningBroadcast();
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.updateDynamicMaxWidth();
    }

    listeningBroadcast(): void {
        const channel = new BroadcastChannel('resource');
        channel.onmessage = (event) => {
            if (event.data.action === 'resourceAdded') {
                const {
                    sectionExternalId,
                    classId,
                    type,
                    contentId,
                    contentExternalId
                } = event.data;

                const data = {
                    content_id: contentId,
                    content_external_id: contentExternalId,
                    section_external_id: sectionExternalId,
                    class_id: classId,
                    type
                };

                let resourceAdded = localStorage.getItem('resourceAdded') || {};

                if (typeof resourceAdded === 'string') {
                    resourceAdded = JSON.parse(resourceAdded);
                }

                if (!resourceAdded[classId]) {
                    resourceAdded[classId] = [];
                }

                const alreadyAdded = resourceAdded[classId].some((item) => item.content_id === contentId);

                if (!alreadyAdded) {
                    resourceAdded[classId].push(data);
                    localStorage.setItem('resourceAdded', JSON.stringify(resourceAdded));
                }

                this.modulesList.map((module) => {
                    module.classes.map((classe: any) => {
                        if (classe.id == classId) {
                            classe.resources.push(data);
                        }
                    });
                });

                this.cdr.detectChanges();
            }
        };
    }

    getTraductions(): void {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('ContentPlanner'),
            ...this.sharedService.getTranslationsOf('Disciplines'),
        };

        const language = localStorage.getItem('currentLanguage');

        if (language) {
            this.currentLenguage = language;
        }

        this.loadContentTypes();
    }

    loadContentTypes() {
        this.contentTypes = {
            File: this.i18n.disciplines_page_file,
            Youtube: this.i18n.disciplines_youtube,
            TextPage: this.i18n.disciplines_text_page,
            Link: this.i18n.disciplines_link,
            Assignment: this.i18n.disciplines_docs_assignment,
            UploadAssignment: this.i18n.disciplines_docs_assignment,
            Forum: this.i18n.disciplines_forum,
            InternalQuiz: this.i18n.disciplines_upload_quiz,
            Scorm: this.i18n.disciplines_scorm,
            LTI: this.i18n.disciplines_page_lti,
            Conference: this.i18n.disciplines_conference,
            UploadQuiz: this.i18n.disciplines_upload_quiz_external,
        };
    }

    getParamsFromRoute(): void {
        this.planningExternalId = this.route.snapshot.params.planning_external_id;
        this.type = this.route.snapshot.params.type;
    }

    getPlanning(): void {
        if (this.planningExternalId) {
            this.contentPlannerService.getPlanningDetail(this.planningExternalId).subscribe({
                next: (response) => {
                    this.planningDetail = response;
                    this.planningDetail.degree_compoente = this.formatedDegreeComponente(response);
                    if (response.mode_organization) {
                        this.selectedModeOrganization = response.mode_organization;
                    }
                },
                error: (error) => {
                    this.planningDetail = [];
                }
            });
        }
    }

    formatedDegreeComponente(response: any): string {
        let degreeComponente = '';
        if (response && response.degree_name && response.component_name) {
            const degree = response.degree_name[0] !== 'G' ? this.i18n.planner_degree.toLowerCase() : '';
            degreeComponente = `${response.degree_name} ${degree} - ${response.component_name}`;
        }
        return degreeComponente;
    }

    createFormModule(): void {
        this.form = this.fb.group({
            modules: ['', Validators.required],
            number_estimated: ['', Validators.required],
            classes: this.fb.array([this.createEmptyClass()]),
        });
    }

    createEmptyClass(): FormGroup {
        return this.fb.group({
            description: ['', Validators.required],
            resources: [[]],
            activities: [[], Validators.required],
            materials: [[], Validators.required],
            procedures: [[], Validators.required],
        });
    }

    getResources(): void {
        this.resourcesList = [
            {
                name: this.i18n.planner_content_file,
                type: 'File',
                value: 'rsc_file_enabled'
            },
            {
                name: this.i18n.planner_content_link,
                type: 'Link',
                value: 'rsc_link_enabled'
            },
            {
                name: this.i18n.planner_content_youtube,
                type: 'Youtube',
                value: 'rsc_youtube_enabled'
            },
            {
                name: this.i18n.planner_content_textpage,
                type: 'TextPage',
                value: 'rsc_textpage_enabled'
            },
            {
                name: this.i18n.planner_content_scorm,
                type: 'Scorm',
                value: 'rsc_scorm_enabled'
            },
            {
                name: this.i18n.planner_content_lti,
                type: 'LTI',
                value: 'rsc_lti_enabled'
            },
            {
                name: this.i18n.planner_content_conference,
                type: 'Conference',
                value: 'rsc_conference_enabled'
            },
            {
                name: this.i18n.planner_content_external_quiz,
                type: 'UploadQuiz',
                value: 'rsc_external_quiz_enabled'
            },
        ];

        this.activitiesList = [
            {
                name: this.i18n.planner_content_docs_assignment,
                type: 'DocsAssignment',
                value: 'rsc_docs_assignment_enabled'

            },
            {
                name: this.i18n.planner_content_sheets_assignment,
                type: 'SheetsAssignment',
                value: 'rsc_sheets_assignment_enabled'
            },
            {
                name: this.i18n.planner_content_upload_assignment,
                type: 'UploadAssignment',
                value: 'rsc_upload_assignment_enabled'
            },
            {
                name: this.i18n.planner_content_internal_quiz,
                type: 'InternalQuiz',
                value: 'rsc_internal_quiz_enabled'
            },
        ];
    }

    getModules(): void {
        if (this.planningExternalId) {
            this.contentPlannerService.getModules(this.planningExternalId).subscribe({
                next: (response) => {
                    this.modulesList = response.plannings_modules
                        .map((module) => {
                            if (module.methodology_id) {
                                this.selectedMethodologies[module.id] = module.methodology_id;
                            }

                            module.skills = module.skills
                                .map((skill) => ({
                                    ...skill,
                                    label: `${skill.code} - ${skill.description}`
                                }));
                            return module;
                        });
                    this.sanitizeModulesList();
                    this.groupedClasses = this.groupClassesByWeek();

                    if (Object.keys(this.groupedClasses).length) {
                        this.isEmptyGroupedClasses = false;
                    }
                },
                error: () => {
                    this.modulesList = [];
                    this.form.controls.modules.setValue('error');
                }
            });
        }
    }

    groupClassesByWeek(): GroupedClasses {
        const modules = this.modulesList;
        const groupedClasses: GroupedClasses = {};

        modules.forEach((module) => {
            module.classes.forEach((classItem, index) => {
                if (!classItem.idealWeek) {
                    return;
                }

                const week = classItem.idealWeek;
                let className = `${this.i18n.planner_class} ${index + 1}`;

                if (classItem.code) {
                    className += ` - ${classItem.code}`;
                }

                if (!groupedClasses[week]) {
                    groupedClasses[week] = {
                        week,
                        totalClasses: 0,
                        classes: []
                    };
                }

                groupedClasses[week].totalClasses = 1;
                groupedClasses[week].classes.push({
                    id: classItem.id,
                    name: className
                });
            });
        });

        return groupedClasses;
    }

    sanitizeModulesList() {
        if (this.modulesList.length) {
            this.modulesList.map((module, index) => {
                if (index === 0) {
                    this.isExpanded[index] = true;
                }

                module?.classes.map((classe: any, index) => {
                    if (index === 0) {
                        this.isExpandedClass[index] = true;
                    }

                    classe.url_file = this.sanitizer.bypassSecurityTrustResourceUrl(classe.url_file);

                    if (!classe.resources) {
                        classe.resources = [];
                    }

                    if (classe?.methodology_steps?.length) {
                        this.selectedSteps[classe.id] = classe.methodology_steps;
                    }

                    const classId = classe.id.toString();
                    const storedResources = JSON.parse(localStorage.getItem('resourceAdded') || '{}');

                    if (storedResources[classId]) {
                        classe.resources = [...classe.resources, ...storedResources[classId]];
                    }

                    const control = this.fb.control(classe.skills || []);
                    this.form.addControl(`skills_${classId}`, control);

                    this.setSelectedWeek(classId, classe.idealWeek);
                });
            });
        }
    }

    getClassSupportItems(): void {
        this.contentPlannerService.getListItems().subscribe({
            next: (response) => {
                this.materialsList = response.material;
                this.proceduresList = response.procedure;
            },
            error: () => {
                this.materialsList = [];
                this.proceduresList = [];
            }
        });
    }

    controlModalSuccess(value: boolean): void {
        if (typeof value === 'boolean') {
            this.isVisibleSuccessPlanningModal = value;

            if (!value) {
                this.router.navigate(['/class-planner']);
            }
        }
    }

    validateEstimatedClass(EstimatedInput: HTMLInputElement): void {
        if (EstimatedInput) {
            let numericValue = EstimatedInput.value.replace(/[^0-9]/g, '');
            numericValue = numericValue.replace(/^0*(\d+)/, '$1');

            if (numericValue === '0') {
                numericValue = '';
            }

            const numberOfClasses = parseInt(numericValue, 10);
            const classesArray = this.form.get('classes') as FormArray;

            while (classesArray.length > numberOfClasses) {
                classesArray.removeAt(classesArray.length - 1);
            }

            while (classesArray.length < numberOfClasses) {
                classesArray.push(this.createEmptyClass());
            }

            this.form.controls.number_estimated.setValue(numericValue);
            this.updateDynamicMaxWidthTimeout();
        }
    }

    setResourcesItem(value: string, classIndex: number): void {
        const classArray = this.form.get('classes') as FormArray;
        const classControl = classArray.at(classIndex);

        if (classControl) {
            const resourcesControl = classControl.get('resources');

            if (resourcesControl) {
                if (resourcesControl.value.includes(value)) {
                    const itemIndex = resourcesControl.value.indexOf(value);
                    resourcesControl.value.splice(itemIndex, 1);
                } else {
                    resourcesControl.value.push(value);
                }
            }
        }
    }

    resetForm(moduleExternalId: string): void {
        this.form.reset({
            modules: moduleExternalId,
            number_estimated: '',
        });

        const classesArray = this.form.get('classes') as FormArray;
        classesArray.clear();
        classesArray.push(this.createEmptyClass());
    }

    isSubmitButtonDisabled(): boolean {
        const classesArray = this.form.get('classes') as FormArray;

        for (let i = 0; i < classesArray.length; i += 1) {
            const classGroup = classesArray.at(i) as FormGroup;

            if (!classGroup.controls.resources.value.length) {
                return true;
            }
        }
        return false;
    }

    updateDynamicMaxWidthTimeout(): void {
        setTimeout(() => this.updateDynamicMaxWidth(), 500);
    }

    updateDynamicMaxWidth(): void {
        if (this.multiSelectElements) {
            this.multiSelectElements.forEach((e: ElementRef) => {
                const containerWidth = e.nativeElement.parentElement.parentElement.offsetWidth;
                if (window.innerWidth < 640) {
                    e.nativeElement.style.maxWidth = `${containerWidth}px`;
                } else if (window.innerWidth > 640 && window.innerWidth < 768) {
                    e.nativeElement.style.maxWidth = `${(containerWidth / 2) - 8}px`;
                } else {
                    e.nativeElement.style.maxWidth = `${(containerWidth / 3) - 8}px`;
                }
            });
        }
    }

    onSubmit(): void {
        if (this.form.valid && !this.isSubmitButtonDisabled()) {
            const moduleExternalId = this.form.controls.modules.value;
            const payload: PostDetailedPlanning = {
                type: this.type,
                estimated_classes: parseFloat(this.form.controls.number_estimated.value),
                planning_classes: [],
                mode_organization: this.selectedModeOrganization
            };

            const classesArray = this.form.get('classes') as FormArray;

            (classesArray.controls as FormGroup[]).forEach((classGroup: FormGroup) => {
                const planningClass = {
                    description: classGroup.controls.description.value,
                    rsc_file_enabled: classGroup.controls.resources.value.includes('rsc_file_enabled'),
                    rsc_link_enabled: classGroup.controls.resources.value.includes('rsc_link_enabled'),
                    rsc_youtube_enabled: classGroup.controls.resources.value.includes('rsc_youtube_enabled'),
                    rsc_textpage_enabled: classGroup.controls.resources.value.includes('rsc_textpage_enabled'),
                    rsc_internal_quiz_enabled: classGroup.controls.activities.value.includes('rsc_internal_quiz_enabled'),
                    rsc_scorm_enabled: classGroup.controls.resources.value.includes('rsc_scorm_enabled'),
                    rsc_lti_enabled: classGroup.controls.resources.value.includes('rsc_lti_enabled'),
                    rsc_conference_enabled: classGroup.controls.resources.value.includes('rsc_conference_enabled'),
                    rsc_external_quiz_enabled: classGroup.controls.resources.value.includes('rsc_external_quiz_enabled'),
                    rsc_docs_assignment_enabled: classGroup.controls.activities.value.includes('rsc_docs_assignment_enabled'),
                    rsc_sheets_assignment_enabled: classGroup.controls.activities.value.includes('rsc_sheets_assignment_enabled'),
                    rsc_upload_assignment_enabled: classGroup.controls.activities.value.includes('rsc_upload_assignment_enabled'),
                    material: classGroup.controls.materials.value,
                    procedure: classGroup.controls.procedures.value
                };

                payload.planning_classes.push(planningClass);
            });

            this.contentPlannerService.postDetailedPlanning(this.planningExternalId, moduleExternalId, payload).subscribe({
                next: () => {
                    this.resetForm(moduleExternalId);
                    this.getResources();
                    this.controlModalSuccess(true);
                },
                error: () => {
                    this.platformModalService.toggle('message', this.i18n.planner_error_create_planner, 'close');
                }
            });
        }
    }

    toggleExpand(index: number) {
        const tempState = this.isExpanded[index];
        this.isExpanded.fill(false);
        this.isExpanded[index] = !tempState;
    }

    toggleExpandClass(index: number) {
        const tempState = this.isExpandedClass[index];
        this.isExpandedClass.fill(false);
        this.isExpandedClass[index] = !tempState;
    }

    toggleExpandClassWeek(index: number) {
        const tempState = this.isExpandedClassWeek[index];
        this.isExpandedClassWeek.fill(false);
        this.isExpandedClassWeek[index] = !tempState;
    }

    addNewClass(module_id: number) {
        this.isVisibleModalLoading = true;
        this.messageModalLoading = this.i18n.planner_decision_loading_create_class;

        this.contentPlannerService.postAddClass({ module_id }).subscribe({
            next: (response) => {
                this.getModules();
                this.isVisibleModalLoading = false;
            },
            error: () => {
                this.isVisibleModalLoading = false;
            }
        });
    }

    deleteClass(class_id: number) {
        this.isVisibleModalLoading = true;
        this.messageModalLoading = this.i18n.planner_decision_loading_delete_class;

        this.contentPlannerService.deleteClass(class_id)
            .subscribe(
                {
                    next: (response) => {
                        this.getModules();
                        this.isVisibleModalLoading = false;
                    },
                    error: (error) => {
                        this.isVisibleModalLoading = false;
                        console.log(error);
                    }
                }
            );
    }

    openModalResource(module: any, classroom: any) {
        this.moduleResources = module;
        this.isVisibleResourceModal = true;
        this.classIdResource = classroom.id;

        if (!this?.moduleResources?.section) {
            this.moduleResources.section = classroom.section;
        }
    }

    closeModalResource() {
        this.moduleResources = [];
        this.isVisibleResourceModal = false;
    }

    decisionDeleteResource(resource: any): void {
        this.platformModalService.toggle('decision', this.i18n.planner_decision_delete_resource, {
            forward: () => {
                this.deleteResource(resource.class_id, resource.content_id);
            },
            finally: () => {
                this.platformModalService.close('decision');
            },
        });
    }

    decisionDeleteClass(class_id: number): void {
        this.platformModalService.toggle('decision', this.i18n.planner_decision_delete_class, {
            forward: () => {
                this.deleteClass(class_id);
            },
            finally: () => {
                this.platformModalService.close('decision');
            },
        });
    }

    decisionCreateClass(module: any): void {
        this.platformModalService.toggle('decision', this.i18n.planner_decision_create_class, {
            forward: () => {
                this.addNewClass(module.id);
            },
            finally: () => {
                this.platformModalService.close('decision');
            },
        });
    }

    decisionSavePlanning(type: string): void {
        if (!this.selectedModeOrganization) {
            this.platformModalService.toggle('message', this.i18n.planner_how_organized_sections, 'close');
            this.cdr.detectChanges();
            return;
        }

        const invalidModules = this.getInvalidModules();
        const invalidClasses = this.getInvalidClasses(); // Nova verificação de aulas

        if (invalidModules.length > 0) {
            this.showInvalidModulesAlert(invalidModules);
            this.cdr.detectChanges();
            this.isVisibleModalLoading = false;
            return;
        }

        if (invalidClasses.length > 0) {
            this.showInvalidClassesAlert(invalidClasses); // Exibe alerta para aulas inválidas
            this.cdr.detectChanges();
            this.isVisibleModalLoading = false;
            return;
        }

        this.platformModalService.toggle('decision', this.i18n.planner_decision_save_planning_class, {
            forward: () => {
                this.savePlanning();
            },
            finally: () => {
                this.platformModalService.close('decision');
            },
        });
    }

    private getInvalidModules(): number[] {
        return this.modulesList
            .filter((module) => !this.isModuleValid(module))
            .map((module) => module.id);
    }

    private getInvalidClasses(): { moduleId: number, classId: number }[] {
        const invalidClasses: { moduleId: number, classId: number }[] = [];

        this.modulesList.forEach((module) => {
            module.classes.forEach((classItem) => {
                if (!this.isClassValid(classItem)) {
                    invalidClasses.push({ moduleId: module.id, classId: classItem.id });
                }
            });
        });

        return invalidClasses;
    }

    private isModuleValid(module: any): boolean {
        const selectedMethodology = this.selectedMethodologies[module.id];
        if (!selectedMethodology || !module.methodologies) {
            return true; // Módulo sem metodologia não precisa de validação
        }

        const methodologySteps = module.methodologies.find((m) => m.id === selectedMethodology)?.steps || [];
        const usedSteps = new Set<number>();

        module.classes.forEach((classItem) => {
            const stepsInClass = this.selectedSteps[classItem.id] || [];
            stepsInClass.forEach((step) => usedSteps.add(step));
        });

        return methodologySteps.every((step) => usedSteps.has(step.id));
    }

    private isClassValid(classItem: any): boolean {
        const hasSkills = classItem.skills && classItem.skills.length > 0;
        const hasMethodologySteps = (this.selectedSteps[classItem.id]?.length || 0) > 0;

        return hasSkills && hasMethodologySteps;
    }

    private showInvalidModulesAlert(invalidModules: number[]): void {
        if (invalidModules.length > 0) {
            const moduleCode = this.modulesList.find((m) => m.id === invalidModules[0])?.code || '';
            const message = `O módulo ${moduleCode} não utilizou todas as etapas da metodologia selecionada.`;
            this.platformModalService.toggle('message', message, 'close');
        }

        this.cdr.detectChanges(); // Força a detecção de mudanças
    }

    private showInvalidClassesAlert(invalidClasses: { moduleId: number, classId: number }[]): void {
        if (invalidClasses.length > 0) {
            const { moduleId, classId } = invalidClasses[0]; // Pega a primeira aula inválida
            const moduleCode = this.modulesList.find((m) => m.id === moduleId)?.code || '';
            const classCode = this.modulesList.find((m) => m.id === moduleId)?.classes.find((c) => c.id === classId)?.code || '';

            const message = `A aula ${classCode} no módulo ${moduleCode} não possui habilidades ou metodologias selecionadas.`;
            this.platformModalService.toggle('message', message, 'close');
        }

        this.cdr.detectChanges(); // Força a detecção de mudanças
    }

    deleteResource(classId: number, contentId: number) {
        this.isVisibleModalLoading = true;
        this.messageModalLoading = this.i18n.planner_decision_loading_delete_resource;

        this.contentPlannerService.deleteResource(classId, contentId).subscribe({
            next: (response) => {
                this.modulesList.map((module) => {
                    module.classes.map((classe: any) => {
                        if (classe.id === classId) {
                            const index = classe.resources.findIndex((resource: any) => resource.content_id === contentId);
                            classe.resources.splice(index, 1);
                        }
                    });
                });

                this.refactoryResourceStorage(classId, contentId);
                this.isVisibleModalLoading = false;
                this.cdr.detectChanges();
            },
            error: (error) => {
                console.log('error', error);
            }
        });
    }

    refactoryResourceStorage(classId: number, contentId: number) {
        const resourceAdded = localStorage.getItem('resourceAdded') || '';

        if (resourceAdded[classId]) {
            const resourceJson = JSON.parse(resourceAdded);
            resourceJson[classId] = resourceJson[classId].filter((resource: any) => resource.content_id !== contentId);
            localStorage.setItem('resourceAdded', JSON.stringify(resourceJson));
        } else {
            this.getModules();
        }
    }

    setSkillsItem(value: any, classId: number): void {
        this.modulesList.map((module) => {
            module.classes.map((classe: any) => {
                if (classe.id === classId) {
                    classe.skills = value;
                    const label = module.skills.filter((skill) => value.includes(skill.id)).map((skill) => skill.label);
                    this.formatSelectedItems(label);
                }
            });
        });
    }

    savePlanning() {
        this.isVisibleModalLoading = true;
        this.messageModalLoading = this.i18n.planner_decision_loading_create_planning;

        const classes = this.modulesList.flatMap((module) => module.classes);

        const payloadClasses = classes.map((classItem) => {
            const selectedWeek = this.selectedWeeks[+classItem.id];
            const selectedSteps = this.selectedSteps[classItem.id] || [];
            return {
                ...classItem,
                selectedWeek: selectedWeek.week || null,
                selectedSteps
            };
        });

        const payloadModuleMethodology = this.modulesList.map((module) => {
            const moduleMethodology = {
                module_external_id: module.external_id,
                methodology_id: this.selectedMethodologies[module.id] || null
            };
            return moduleMethodology;
        });

        const payload = {
            planning_external_id: this.planningExternalId,
            mode_organization: this.selectedModeOrganization,
            classes: payloadClasses,
            module_methodology: payloadModuleMethodology
        };

        this.contentPlannerService.patchModuleClass(payload).subscribe({
            next: (response) => {
                this.isVisibleModalLoading = false;
                localStorage.removeItem('resourceAdded');
                this.controlModalSuccess(true);
            },
            error: (error) => {
                this.isVisibleModalLoading = false;
                console.log('error', { error });
            }
        });
    }

    editComponent(module, resource, classroom) {
        const title = module?.section?.title ?? classroom?.section?.title;
        const sectionId = module?.section?.external_id ?? classroom?.section?.external_id;
        const url = `/disciplines/${module.discipline_external_id}/content-assignment/assignment;content=${resource.content_external_id};section=${sectionId};section_title=${title};type=${resource.type};edit=1;class_id=${resource.class_id}`;
        // Abrir a rota em uma nova aba
        window.open(url, '_blank');
    }

    formatDateRange(initialDate: string, finalDate: string): string {
        const startDate = this.createDateWithoutTimezone(initialDate);
        const endDate = this.createDateWithoutTimezone(finalDate);

        const lang = this.getLang();

        const monthStart = startDate.toLocaleDateString(lang, { month: 'long' });
        const dayStart = startDate.toLocaleDateString(lang, { day: '2-digit' });
        const dayEnd = endDate.toLocaleDateString(lang, { day: '2-digit' });

        let dateString = `${dayStart} a ${dayEnd} de ${monthStart}`;

        if (this.currentLenguage === 'en') {
            dateString = `${dayStart} to ${dayEnd} of ${monthStart}`;
        }

        if (this.currentLenguage === 'es') {
            dateString = `${dayStart} al ${dayEnd} de ${monthStart}`;
        }

        return dateString;
    }

    createDateWithoutTimezone(dateString: string): Date {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
    }

    getLang(): string {
        let lang = 'pt-BR';

        if (this.currentLenguage === 'en') {
            lang = 'en-US';
        }

        if (this.currentLenguage === 'es') {
            lang = 'es-ES';
        }

        return lang;
    }

    isWeekSelected(classId: number, week: number): boolean {
        return this.selectedWeeks[classId]?.week === week;
    }

    toggleWeekSelection(classId: number, week: number, item: number): void {
        this.selectedWeeks[classId] = { week };
        this.isExpandedClassWeek[item] = false;
        this.regroupClassesByWeek();
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
        const clickedInside = (event.target as HTMLElement).closest('.tooltip-classweek');
        if (!clickedInside && this.isExpandedClassWeek.length) {
            this.isExpandedClassWeek.fill(false);
        }
    }

    setSelectedWeek(classId: number, week: any) {
        this.selectedWeeks[classId] = { week };
    }

    getGroupedClassesEntries(groupedClasses: GroupedClasses): { week: number, totalClasses: number, classes: { id: number, name: string }[] }[] {
        return Object.values(groupedClasses);
    }

    getWeekName(week: number): string {
        return `${this.i18n.planner_week} ${week}`;
    }

    getLabelClass(totalClasses: number): string {
        const classLabel = totalClasses > 1 ? this.i18n.planner_classes : this.i18n.planner_class;
        return `${totalClasses} ${classLabel.toLowerCase()}`;
    }

    isGroupedClassesEmpty(groupedClasses: GroupedClasses): boolean {
        return Object.keys(groupedClasses).length === 0;
    }

    regroupClassesByWeek(): void {
        const newGroupedClasses: GroupedClasses = {};

        this.modulesList.forEach((module) => {
            module.classes.forEach((classItem, index) => {
                const week = this.selectedWeeks[classItem.id]?.week ?? classItem.idealWeek;

                if (week === null || week === undefined) {
                    return;
                }

                let className = `${this.i18n.planner_class} ${index + 1}`;

                if (classItem.code) {
                    className += ` - ${classItem.code}`;
                }

                if (!newGroupedClasses[week]) {
                    newGroupedClasses[week] = {
                        week,
                        totalClasses: 0,
                        classes: []
                    };
                }

                newGroupedClasses[week].totalClasses += 1;
                newGroupedClasses[week].classes.push({
                    id: classItem.id,
                    name: className
                });
            });
        });

        this.groupedClasses = newGroupedClasses;
        this.isEmptyGroupedClasses = this.isGroupedClassesEmpty(newGroupedClasses);
    }

    getClassName(module: any, index: number): string {
        const decimalIndex = index.toString().padStart(2, '0');
        return `${this.i18n.planner_class} ${index} - ${module.code}A${decimalIndex}`;
    }

    gotoDocs(url: SafeUrl): void {
        if (url) {
            // Converte a SafeUrl de volta para uma string comum
            const safeUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
            if (safeUrl) {
                window.open(safeUrl, '_blank');
            }
        }
    }

    onDragStarted(index: number): void {
        this.currentDragIndex = index;
        // Aplique a classe 'hidden-drag' ao elemento arrastado
        const draggedElement = document.querySelector(`[drag-id="${index}"]`);
        if (draggedElement) {
            draggedElement.classList.add('hidden-drag');
        }
    }

    onDragEnded(): void {
        // Remova a classe 'hidden-drag' após o término do arrasto
        const draggedElement = document.querySelector(`[drag-id="${this.currentDragIndex}"]`);
        if (draggedElement) {
            draggedElement.classList.remove('hidden-drag');
        }
        this.currentDragIndex = null;
    }

    dropLesson(event: CdkDragDrop<any[]>, module: any) {
        this.moveItemInArray(module.classes, event.previousIndex, event.currentIndex);
        this.currentDragIndex = null;
    }

    moveItemInArray(array: any[], fromIndex: number, toIndex: number): void {
        // Valida se o índice de origem e o índice de destino estão dentro dos limites do array
        if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length) {
            console.warn('Índices fora dos limites do array');
            return;
        }

        // Remove o item da posição original
        const item = array.splice(fromIndex, 1)[0];

        // Insere o item na nova posição
        array.splice(toIndex, 0, item);

        const payload = { classes: array };

        this.toastBaseMessenger.messengerToast('base-info', this.i18n.planner_saving_changes, true);

        this.contentPlannerService.patchReorderClass(payload).subscribe({
            next: () => {
                // this.getModules();
                this.toastBaseMessenger.messengerToast('base-success', this.i18n.planner_saved_changes, true);
            },
            error: (error) => {
                this.platformModalService.toggle(
                    'message',
                    {
                        message: this.i18n.planner_error_reorder_class,
                        icon_existence: true,
                        icon_color: '#F36C48',
                        custom_icon: 'attention-icon',
                    },
                    'close'
                );
                console.log('error', error);
            }
        });
    }

    formatSelectedItems(value): void {
        const multiselectLabel = document.querySelector('.custom-multiselect .p-multiselect-label');
        if (multiselectLabel) {
            const formattedText = value.join('<br>');
            if (formattedText && value.length) {
                multiselectLabel.innerHTML = formattedText;
            } else {
                multiselectLabel.innerHTML = this.i18n.planner_select;
            }
        }

        this.cdr.detectChanges();
    }

    checkClassByModule(module: any): boolean {
        // Verifica se todas as aulas do módulo possuem habilidades e etapas da metodologia
        return module.classes.every((classItem: any) => {
            const hasSkills = classItem.skills && classItem.skills.length > 0;
            const hasMethodologySteps = (this.selectedSteps[classItem.id]?.length || 0) > 0;

            return hasSkills && hasMethodologySteps;
        });
    }

    selectedMethodology(methodologyId: number, moduleId: number): void {
        if (this.selectedMethodologies[moduleId] !== methodologyId) {
            return;
        }

        // Verifica se a metodologia já está selecionada para o módulo
        if (this.selectedMethodologies[moduleId] === methodologyId) {
            // Exibir modal de confirmação antes de desmarcar a metodologia
            this.platformModalService.toggle('decision', 'Deseja realmente desmarcar esta metodologia? Todas as etapas associadas nas outras aulas deste módulo também serão desmarcadas.', {
                forward: () => {
                    this.selectedMethodologies[moduleId] = null;
                    // Limpar as etapas selecionadas para as aulas deste módulo
                    this.modulesList.forEach((module) => {
                        if (module.id === moduleId) {
                            module.classes.forEach((classItem) => {
                                this.selectedSteps[classItem.id] = [];
                            });
                        }
                    });
                    this.platformModalService.close('decision');
                },
                finally: () => {
                    this.platformModalService.close('decision');
                },
            });
        } else {
            // Selecionar a metodologia se ainda não estiver selecionada
            this.selectedMethodologies[moduleId] = methodologyId;
            // Limpar as etapas selecionadas para as aulas desse módulo
            this.modulesList.forEach((module) => {
                if (module.id === moduleId) {
                    module.classes.forEach((classItem) => {
                        this.selectedSteps[classItem.id] = []; // Limpa as etapas selecionadas
                    });
                }
            });
        }
    }

    toggleStepSelection(stepId: number, methodologyId: number, classId: number, moduleId: number): void {
        // Verifica se a metodologia está selecionada para o módulo
        if (!this.selectedMethodologies[moduleId] || this.selectedMethodologies[moduleId] === undefined) {
            this.selectedMethodologies[moduleId] = methodologyId;
        }

        if (this.selectedMethodologies[moduleId] !== methodologyId) {
            return; // Impede a seleção se a metodologia não estiver selecionada
        }

        // Inicializa o array se estiver undefined
        if (!this.selectedSteps[classId]) {
            this.selectedSteps[classId] = [];
        }

        const index = this.selectedSteps[classId].indexOf(stepId);

        if (index === -1) {
            // Verifica se já existem 3 etapas selecionadas
            if (this.selectedSteps[classId].length < 3) {
                this.selectedSteps[classId].push(stepId);
            } else {
                console.log('Só é possível selecionar até 3 etapas por aula.');
            }
        } else {
            // Remove a etapa se já estiver selecionada
            this.selectedSteps[classId].splice(index, 1);
        }
    }

    isStepSelected(stepId: number, classId: number): boolean {
        return this.selectedSteps[classId]?.includes(stepId) ?? false;
    }
}
