import {
    Component, EventEmitter, Input, OnChanges, Output, ViewChild
} from '@angular/core';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { Methodology } from 'src/app/pages/content-planner/interfaces/methodologies.interface';
import { ContentPlannerService } from 'src/app/pages/content-planner/services/content-planner.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-modal-select-methodologies',
    templateUrl: './modal-select-methodologies.component.html',
    styleUrls: ['./modal-select-methodologies.component.scss']
})
export class ModalSelectMethodologiesComponent implements OnChanges {
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;
    @Output() closeModalSelectMethodologies = new EventEmitter();
    @Output() submitSelectedMethodologies = new EventEmitter();
    @Input() isVisibleModalSelectMethodologies = false;
    @Input() createdModule = false;
    @Input() editModeMehodology = false;
    @Input() i18n: any = {};
    @Input() methodologiesSelectedList: Methodology[] = [];
    methodologies: Methodology[] = [];
    filteredMethodologies: Methodology[] = [];
    isSelected = false;
    isAllSelected = false;
    loading = false;
    page = 1;
    per_page = 12;
    searchQuery = '';
    searchQueryByComponent = '';
    guidelines: any = [];
    isExpanded: boolean[] = [];
    isExpandedSegment: boolean[] = [];
    isExpandedComponent: boolean[] = [];
    componentsList: any = [];
    methodologiesList: any = [];
    tempMethodologiesList: any = [];
    selectedCount: number;

    paginationParams: any = {};

    constructor(private contentPlannerService: ContentPlannerService, private sharedService: SharedService) { }

    ngOnChanges(): void {
        if (this.isVisibleModalSelectMethodologies) {
            this.getMethodologies(this.searchQuery, this.page, this.per_page);
        }

        if (this.methodologiesSelectedList.length) {
            this.selectedCount = this.methodologies.filter((m) => m.isSelected === true).length;
        }
    }

    async getSearchMethodology(query: string) {
        const search = await this.sharedService.debounce(query);

        if (typeof search === 'string') {
            this.searchQuery = search;
            this.page = 1;
            this.getMethodologies(this.searchQuery, this.page, this.per_page);
        }
    }

    getMethodologies(query: string, page: number, per_page): void {
        this.loading = true;
        this.contentPlannerService.getMethodologies(query, page, per_page).subscribe({
            next: (response) => {
                this.methodologies = response;
                this.checkSelectedMethodologies();
                this.loading = false;
            },
            error: () => {
                this.methodologies = [];
                this.filteredMethodologies = [];
            }
        });
    }

    checkSelectedMethodologies() {
        if (this.methodologiesSelectedList.length) {
            this.methodologiesSelectedList.forEach((methodology) => {
                const index = this.methodologies.findIndex((m) => m.id === methodology.id);
                if (index !== -1) {
                    this.methodologies[index].isSelected = methodology.isSelected;
                }
            });
        }
    }

    refreshPagination(response): void {
        this.paginationParams = {
            items: [...Array(response.lastPage).keys()],
            itemsPerPage: this.per_page,
            visibleButtons: 5,
            activePage: this.page
        };
        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }

    onSubmit() {
        this.submitSelectedMethodologies.emit(this.methodologiesSelectedList);
    }

    closeModal(): void {
        this.closeModalSelectMethodologies.emit(false);
    }

    toggleExpandMethodology(index: number): void {
        this.isExpanded[index] = !this.isExpanded[index];
    }

    selectedMethodology(methodology: Methodology, event: any): void {
        // Criar uma cópia do objeto metodologia para evitar a modificação direta
        const updatedMethodology = { ...methodology };
        this.selectedCount = this.methodologies.filter((m) => m.isSelected === true).length;

        console.log('Selected count:', this.selectedCount);

        if (updatedMethodology.isSelected) {
            // Permitir desmarcar se já estiver selecionada
            updatedMethodology.isSelected = false;
        } else if (this.selectedCount < 3) {
            // Permitir a seleção se houver menos de 3 metodologias selecionadas
            updatedMethodology.isSelected = true;
        } else {
            updatedMethodology.isSelected = false;
            return;
        }

        // Atualizar o objeto original no array de metodologias
        const index = this.methodologies.findIndex((m) => m === methodology);
        if (index !== -1) {
            this.methodologies[index] = updatedMethodology;
        }

        // Atualizar a lista de metodologias selecionadas
        this.methodologiesSelectedList = this.methodologies.filter((m) => m.isSelected);
        this.selectedCount = this.methodologiesSelectedList.length;

        console.log('Selected count:', this.selectedCount);
    }
}
