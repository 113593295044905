import {
    Component, ElementRef, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import {
    UntypedFormBuilder, UntypedFormGroup, Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MessengerToastService } from 'src/app/components/messenger-toast/messenger-toast.service';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';
import { GoogleChatService } from 'src/app/services/google-chat.service';
import { GoogleSpacesService } from 'src/app/services/googleSpaces/google-spaces.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { RestoreFormattingService } from 'src/app/shared/restore-formatting.service';
import { SharedService } from 'src/app/shared/shared.service';

import { OverviewService } from './overview.service';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
    @ViewChild('descriptionText') descriptionText: ElementRef;
    disciplineDetail: any = [];
    showModal: boolean;
    isLoading = true;
    searchForm: UntypedFormGroup;
    i18n: any = [];
    currentLanguage: any;
    discipline_external_id: string;
    dataCertificate: any;
    isVisibleModalEmenta = false;
    isVisibleModalSend = false;
    isVisibleModalCreateGoogleSpace = false;
    modalSendType: 'mail' | 'chat' = 'mail';
    unsubscribe$: Subject<boolean> = new Subject();
    discipline_id: any;
    user: any = {};
    userList: any[] = [];

    isTeacher = false;
    isAdmin = false;
    isNotStandalone = false;
    chatEmails: string[] = [];
    hasGoogleSpaceCreated = false;
    googleChatUrl = '';

    constructor(
        private router: Router,
        private disciplineDetailService: DisciplineDetailService,
        private FormBuilder: UntypedFormBuilder,
        private sharedService: SharedService,
        private restoreFormattingService: RestoreFormattingService,
        private googleChatService: GoogleChatService,
        private localStorageService: LocalStorageService,
        private platformModalService: PlatformModalsService,
        private overviewService: OverviewService,
        private googleSpacesService: GoogleSpacesService,
        private toastMessenger: MessengerToastService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.isNotStandalone = this.localStorageService.isNotStandalone();
        this.getDisciplineId();
        this.getDisciplineDetail();
        this.createOverviewForm();
        this.getTranslations();
    }

    ngOnDestroy() {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    getUser() {
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;

            if (this.user?.role_type === 'teacher') {
                this.isTeacher = true;
            }

            if (this.user?.role_type === 'admin') {
                this.isAdmin = true;
            }
        });
    }

    overviewIsLoading() {
        this.isLoading = true;
    }

    overviewIsNotLoading() {
        this.isLoading = false;
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Modal') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getDisciplineId() {
        this.discipline_external_id = this.router?.url.split('/')[2];
    }

    students: any = [];
    teachers: any = [];
    participants: any = [];
    getDisciplineDetail() {
        this.overviewIsLoading();
        this.disciplineDetailService
            .disciplineBasic$
            .subscribe((discipline: any) => {
                this.getUser();

                this.disciplineDetail = { ...discipline };
                if (this.disciplineDetail.participants?.student?.length) {
                    this.students = this.disciplineDetail.participants?.student?.map((student) => ({ ...student, isStudent: true }));
                    this.orderByName(this.students);
                }

                if (this.disciplineDetail.participants?.teacher?.length) {
                    this.teachers = this.disciplineDetail.participants?.teacher?.map((teacher) => ({ ...teacher, isStudent: false }));
                    this.orderByProfile(this.teachers);
                }

                this.participants = [...this.teachers, ...this.students];

                if (this.disciplineDetail?.description) {
                    this.restoreFormattingService.restoreFormatting(this.descriptionText);
                }

                if (discipline.google_chat_space) {
                    this.hasGoogleSpaceCreated = true;
                    const googleChatSpace: string = discipline.google_chat_space.replace('spaces/', '');
                    this.googleChatUrl = `https://mail.google.com/chat/u/0/#chat/space/${googleChatSpace}`;
                }

                if (Object.keys(discipline).length !== 0) {
                    this.overviewIsNotLoading();
                }
            });
    }

    orderByProfile(array: []) {
        return array.sort((a: any, b: any) => (a.role_name < b.role_name ? -1 : 1));
    }

    // eslint-disable-next-line class-methods-use-this
    orderByName(array: []) {
        return array.sort((a: any, b: any) => (a.name < b.name ? -1 : 1));
    }

    emitCertificate(certificate: any) {
        this.getCertificate(certificate);
    }

    getCertificate(emited_id: any) {
        this.disciplineDetailService
            .getEmitedCertificates(emited_id)
            .subscribe({
                next: (blob: Blob) => {
                    const file = new Blob([blob], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL, '_blank', 'width=800, height=600');
                },
                error: (err) => {
                    this.platformModalService.toggle('message', err.error.error, 'close');
                }
            });
    }

    // Forms pronto pra outra versão
    createOverviewForm() {
        this.searchForm = this.FormBuilder.group({
            text: ['', [Validators.required]],
            nivelEnsino: ['', [Validators.required]],
            modalidades: ['', [Validators.required]],
            codTurma: ['', [Validators.required]],
            nomeTurma: ['', [Validators.required]],
            cursoFilter: ['', [Validators.required]],
            nivelFilter: ['', [Validators.required]],
            modalidadeFilter: ['', [Validators.required]],
            anosFilter: ['', [Validators.required]],
            mesesFilter: ['', [Validators.required]],
        });
    }

    closeModal() {
        this.showModal = false;
    }

    openModal() {
        this.searchForm.controls.codTurma.setValue('');
        this.searchForm.controls.nomeTurma.setValue('');
        this.searchForm.controls.nivelEnsino.setValue('');
        this.searchForm.controls.modalidades.setValue('');
        this.showModal = !this.showModal;
    }

    toggleModalEmenta(): void {
        this.isVisibleModalEmenta = !this.isVisibleModalEmenta;
    }

    toggleModalSend(type: 'mail' | 'chat'): void {
        this.isVisibleModalSend = !this.isVisibleModalSend;
        this.modalSendType = type;
    }

    callSyncMembersGoogleSpace() {
        this.googleSpacesService.requestChatScope(this.returnToken);
    }

    returnToken = (token: string) => {
        this.syncGoogleSpace(token);
    };

    syncGoogleSpace(token: string) {
        const params = {
            code: token,
            disc_external_id: this.discipline_external_id
        };
        this.overviewService.syncGoogleSpace(params).subscribe({
            next: (response) => {
                this.toastMessenger.messengerToast(
                    'success',
                    this.i18n.disciplines_sync_google_chat_initiated,
                    true
                );
            },
            error: (err) => {
                this.platformModalService.toggle('message', err.error.error, 'close');
            }
        });
    }

    callGoogleSpace() {
        if (this.hasGoogleSpaceCreated) {
            this.openGoogleSpace(this.googleChatUrl);
            return;
        }

        this.toggleModalCreateSpaceGoogleChat();
    }

    toggleModalCreateSpaceGoogleChat() {
        this.isVisibleModalCreateGoogleSpace = !this.isVisibleModalCreateGoogleSpace;
    }

    receiveCreateGoogleSpace(params) {
        this.createGoogleSpace(params);
    }

    createGoogleSpace(params) {
        this.toggleModalCreateSpaceGoogleChat();
        // this.platformModalService.toggle('loading');
        this.googleSpacesService.showMessage(
            this.i18n.disciplines_space_wait,
            this.i18n.disciplines_space_wait_description,
            'toast-info'
        );

        this.overviewService
            .createGoogleSpace(params).subscribe({
                next: (chat) => {
                    // this.platformModalService.toggle('loading');
                    this.openGoogleSpace(chat.google_chat_url);
                    this.googleChatUrl = chat.google_chat_url;
                    this.hasGoogleSpaceCreated = true;
                },
                error: (err) => {
                    // this.platformModalService.toggle('loading');
                    let message: string = this.i18n[err.error.error || err.error.message];

                    console.log(err);
                    if (!message) {
                        message = this.i18n.disciplines_have_an_error;
                    }

                    this.platformModalService.toggle('message', message, {
                        finally: () => {
                            this.platformModalService.close('message');
                            this.toggleModalCreateSpaceGoogleChat();
                        }
                    });
                }
            });
    }

    openGoogleSpace(google_chat_space: string) {
        window.open(google_chat_space, '_blank');
    }

    openEmailBox(email: string): void {
        const w = 650;
        const h = 600;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const l = Number(screenWidth / 2 - w / 2);
        const t = Number(screenHeight / 3 - h / 3);

        const features = `location=yes, scrollbars=yes, status=yes, resizable=no, width=${w}, height=${h}, top=${t}, left=${l}`;
        const url = `https://mail.google.com/mail/?view=cm&fs=1&su=&body=&bcc=${email}&authuser=${this.user?.email}`;

        window.open(url, '_blank', features);
    }

    openChat(email: string): void {
        this.googleChatService.requestChatScope([email]);
    }

    receiveNewDescription(event: Event): void {
        this.disciplineDetail.description = event;
        this.disciplineDetailService.disciplineBasic$.next(this.disciplineDetail);
        setTimeout(() => {
            if (this.disciplineDetail?.description) {
                this.restoreFormattingService.restoreFormatting(this.descriptionText);
            }
        }, 20);
    }
}
