<section>
    <!-- Back to contents -->
    <div class="w-full">
        <button
            type="button"
            (click)="returnRouteContent()"
            class="text-customGray-default flex items-center gap-2 mb-2 max-w-max"
        >
            <i class="iconify-inline" data-icon="bi:chevron-left"></i>
            <span>{{ i18n.disciplines_return_content }}</span>
        </button>
    </div>

    <div 
        class="flex flex-col 3md:flex-row w-full" 
        [ngClass]="{'gap-6': !expanded}"
    >

        <!-- Content View -->
        <ng-container *ngIf="!isLoading">
        <div 
            class="whitespace-normal overflow-hidden w-full"
        >

            <!-- Control contents navigation -->
            <div
            class="flex justify-between items-center py-3 px-3 text-center md:px-16 rounded-lg bg-customBlue-royal text-customWhite-default"
            style="min-height: 54px"
            >
            <button
                (click)="previousContent()"
                class="text-3xl"
                [ngClass]="{
                'disabled:opacity-20 cursor-not-allowed': content?.previous_section === null
                }"
                [disabled]="content?.previous_section === null"
            >
                <i
                class="iconify-inline"
                data-icon="entypo:chevron-with-circle-left"
                ></i>
            </button>
            <span
                class="font-bold text-xl px-2"
                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
                {{ content?.title }}
            </span>
            <button
            (click)="nextContent()"
            class="text-3xl"
            [ngClass]="{
                'disabled:opacity-20 cursor-not-allowed': content?.next_section === null
            }"
            [disabled]="content?.next_section === null"
            >
                <i
                class="iconify-inline"
                data-icon="entypo:chevron-with-circle-right"
                ></i>
            </button>
            </div>

            <!-- Content type description and expand view -->
            <div class="mt-3 mb-5 rounded-md border border-customBlue-glass bg-customWhite-default">
            <div class="flex p-4 justify-between gap-4 text-customGray-default">
                <div class="flex items-center gap-2">
                <app-content-types-icons class="content-types-icons" [type]="content?.type" [color]="'#A9AEB3'"></app-content-types-icons>
                <p>{{ getTraductionTypeContent(content?.type) }}</p>
                </div>
                <div class="flex items-center gap-4">
                <app-timer
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [content]="content"
                ></app-timer>

                <button 
                    type="button" 
                    class="hidden 3md:flex items-center gap-2 border border-customBlue-default py-1 px-3 text-customBlue-default rounded" 
                    (click)="expandView()"
                    style="max-width: 240px;"
                >
                    <div 
                        [ngClass]="{'hidden' : expanded}"
                    >
                        <span 
                            class="iconify-inline text-24 w-8 h-8" 
                            data-icon="fluent:arrow-expand-20-regular"
                            
                        ></span>
                    </div>

                    <div
                        [ngClass]="{'hidden' : !expanded}"
                    >
                        <span 
                            class="iconify-inline text-24 w-8 h-8" 
                            data-icon="tabler:notebook"
                            
                        ></span>
                    </div>
                    <span 
                        class="font-bold"
                        style="line-height: normal;"
                    >
                        {{ expanded ? i18n.discipline_expand_content : i18n.discipline_expanded_content }}
                    </span>
                </button>
                </div>
            </div>
            </div>

            <!-- Info Status Delivery -->
            <ng-container *ngIf="content?.completion_rule !== 'TIME' && (content?.end_date !== '' || content?.coursework_completed_at !== '')">
            <app-info-status-delivery
                [content]="content"
            ></app-info-status-delivery>
            </ng-container>

        <!-- Content Locked -->
        <div *ngIf="is_locked" class="mb-8 rounded-md border border-customBlue-glass bg-customWhite-default">
          <div class="my-6 flex gap-2 justify-center items-center text-customGray-default">
            <div>
              <span class="iconify w-7 h-7" data-icon="fluent-emoji-high-contrast:locked"></span>
            </div>
            <span>
              {{ this.i18n.disciplines_release_activity }}
              <span (click)="goToContent(section_external_id, conditionalContent?.external_id)" class="font-bold cursor-pointer">
                {{ conditionalContent?.title }}
              </span>
            </span>
          </div>
        </div>

        <!-- Content Rendering -->
        <div 
            *ngIf="!is_locked && content?.type !== 'Forum'" 
            class="mb-8 rounded-md border border-customBlue-glass bg-customWhite-default"
            [ngClass]="{'mt-24' : content?.type === 'UploadQuiz' && user.role_type !== 'student' }"
        >
          <ng-container *ngIf="content?.type !== 'InternalQuiz' && content?.description">
            <div class="m-4 flex items-center" *ngIf="content?.author?.name">
              <div class="lg:w-12 items-center justify-center">
                <app-user-frame [img_src]="content?.author?.image" [user_name]="content?.author?.name"></app-user-frame>
              </div>
              <span class="text-16 user-name ml-1">{{ content?.author?.name }}</span>
            </div>
            <div
              #textDescription
              [innerHTML]="content?.description | safeHTML"
              class="mt-4 m-4 custom-scroll-thumb text-customGray-dark text-16 "
            ></div>

                <hr class="text-customBlue-glass" />
            </ng-container>

            <ng-container *ngIf="errorRequest">
                <app-empty-data class="block p-4"></app-empty-data>
            </ng-container>

            <!-- QUESTIONNAIRE -->
            <ng-container *ngIf="content?.type === 'InternalQuiz'">
            <div
                [ngSwitch]="content?.type"
                *appCheckPermissions="
                'questionnaire';
                type: 'list'"
            >
                <app-content-view-quiz
                *ngSwitchCase="'InternalQuiz'"
                [content]="content"
                class="p-4 pt-0 block">
                </app-content-view-quiz>
            </div>
            <!-- Case user not have permissions  -->
            <p *ngIf="!permissionQuestionnaire['questionnaire.list']"
            class="p-4 text-center text-customGray-dark">{{ i18n.disciplines_content_view_not_have_access_quiz }}</p>
            </ng-container>

            <!-- RECORDED CONFERENCE -->
            <ng-container *ngIf="content?.type === 'Conference'">
                <div
                    *ngIf="!content?.contentSpecific?.recordings?.video.length"
                    class="p-4"
                >
                <a
                    class="border border-customBlue-default rounded px-4 py-2 text-customBlue-default font-medium"
                    [href]="content.contentSpecific?.conference?.url"
                    target="_blank"
                    rel="nofollow"
                    >{{ i18n.disciplines_content_view_btn_conference }}</a
                >
                </div>
                <app-conference-checker
                *ngIf="content?.type === 'Conference' && !content?.contentSpecific?.recordings?.video.length"
                [user]="user"
                (conferenceFound)="includeInContentObject($event)"
                >
                </app-conference-checker>
                <div
                class="recorded-conferences flex flex-col justify-center gap-10"
                *ngIf="content?.contentSpecific?.recordings?.video.length"
                >
                <div class="video-iframes">
                    <div
                    class="video-iframe"
                    style="
                        border-radius: 0.3125rem;
                        overflow: hidden;
                        margin: 1rem;
                        position: relative;
                    "
                    *ngFor="let video of content?.contentSpecific?.recordings?.video"
                    >
                    <iframe
                        width="100%"
                        height="450"
                        [src]="video['public_url'] | safeUrl"
                        allowfullscreen
                    ></iframe>
                    <!-- <button
                        class="manage-recordings-btn absolute top-0 left-0 flex items-center justify-center"
                        *ngIf="this.user.role_type !== 'student'"
                        (click)="
                        prepareParamateresToUploadModal('video');
                        sharedService.modalOpen()
                        "
                    >
                        <img
                        class="gear-outline-icon"
                        src="./assets/svg/gear-outline.svg"
                        />
                        {{ i18n.disciplines_content_menage }}
                    </button> -->
                    </div>
                </div>
                <div
                    class="chat-iframes m-4 flex flex-col items-center flex-start justify-start"
                    
                >
                    <header
                    class="chat-header flex items-center justify-center"
                    (click)="toggleVideoConferenceChatVisibility()"
                    >
                    <p *ngIf="content?.contentSpecific?.recordings?.video.length === 1">
                        {{ i18n.disciplines_chat_classroom }}
                    </p>
                    <p *ngIf="content?.contentSpecific?.recordings?.video.length > 1">
                        {{ i18n.disciplines_chat_classrooms }}
                    </p>
                    </header>
                    
                    <div class="w-full overflow-hidden">
                        <!-- IF-THERE-ARE-NO-CHAT -->
                        <div
                            [@collapse]="collapseChat"
                        class="no-chat flex flex-col items-center justify-center"
                        *ngIf="!content?.contentSpecific?.recordings?.text.length"
                        >
                        <img class="atention-icn" src="./assets/svg/attention.svg" />
                        <p>
                            {{ i18n.disciplines_historic_chat_not_found_1 }}
                        </p>
                        <ng-container *ngIf="user?.role_type !== 'student'">
                                <p>
                                    {{ i18n.disciplines_historic_chat_not_found_2 }}
                                </p>
        
                                <app-upload-inline
                                    #uploadInline
                                    [textUploadBtn]="'Procurar no Google Drive'"
                                    [multiSelect]="true"
                                    [files]="recordingsTextFile"
                                    [fileType]="'document'"
                                    [maxFiles]="1"
                                    [showDate]="false"
                                    [showOpenBtn]="false"
                                    [showReplaceBtn]="false"
                                    [showSendTitle]="false"
                                    (filesEvent)="receiveRecordingsTextFile($event)"
                                ></app-upload-inline>

                            <app-upload-file-list
                                [showSendTitle]="true"
                                [files]="recordingsTextFile"
                                [i18n]="i18n"
                                [currentLanguage]="currentLanguage"
                                [showDate]="false"
                                [showOpenBtn]="false"
                                [showReplaceBtn]="true"
                                [headerText]="'Anexos'"
                                (sendRemoveFile)="uploadInline.removeFile($event)"
                                (sendReplaceFile)="uploadInline.replaceFile($event)"
                            ></app-upload-file-list>

                                <!-- <div
                                    class="google-drive-btn btn flex items-center justify-center"
                                    (click)="
                                        prepareParamateresToUploadModal('text');
                                        sharedService.modalOpen()
                                    "
                                >
                                    <img
                                        class="simple-google-drive-icon"
                                        src="./assets/svg/simple-google-drive.svg"
                                    />
                                    {{ i18n.disciplines_google_search }}
                                </div> -->
        
                                <div 
                                    *ngIf="this.recordingsTextFile?.length && !isAddingRecordingText"
                                    class="mb-6"
                                >
                                    <button 
                                        (click)="addRecording(this.recordingsTextFile)"
                                        class="bg-customBlue-default px-12 py-2 rounded text-customWhite-default font-medium"
                                    >
                                        {{ i18n.disciplines_conference_add_file }}
                                    </button>
                                </div>
        
                                <ng-container *ngIf="isAddingRecordingText">
                                    <app-bullets-loader></app-bullets-loader>
                                </ng-container>
                        </ng-container>
                        </div>
                
                        <!-- IF-THERE-ARE-CHAT -->
                        <div
                            [@collapse]="collapseChat"
                            *ngIf="content?.contentSpecific?.recordings?.text.length"
                            class="flex flex-col justify-center gap-4 bg-customWhite-default"
                        >
                        <div
                            class="chat-iframe"
                            *ngFor="let text of content?.contentSpecific?.recordings?.text"
                        >
                            <iframe
                            width="100%"
                            height="450"
                            [src]="text['public_url'] | safeUrl"
                            allowfullscreen
                            ></iframe>
                            <!-- <button
                            class="manage-recordings-btn absolute top-0 left-0 flex items-center justify-center"
                            *ngIf="this.user.role_type !== 'student'"
                            (click)="
                                prepareParamateresToUploadModal('document');
                                sharedService.modalOpen()
                            "
                            >
                            <img class="gear-outline-icon" src="./assets/svg/gear.svg" />
                            {{ i18n.disciplines_content_menage }}
                            </button> -->
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </ng-container>
            <!-- END RECORDED CONFERENCE -->

            <!-- UPLOAD -->
            <ng-container
                *ngIf="user.role_type === 'student' && content?.type === 'UploadAssignment'"
            >
                <app-content-task-upload
                  #contentTaskUpload
              [content]="content"
                [files]="filesToUploadModal"
                [maxFiles]="maxFiles"
                [fileType]="'noForms'"
                [delivery_allowed]="content?.delivery_allowed"
                [section_external_id]="section_external_id"
                [content_external_id]="content_external_id"
                (reloadStatus)="getReloadStatus()"
                ></app-content-task-upload>
            </ng-container>

            <!-- EMPTY DATA -->
            <app-empty-data
                class="py-4 block"
                *ngIf="user.role_type !== 'student' && (content?.type === 'DocsAssignment' || content?.type === 'SheetsAssignment')"
                [text]="i18n.disciplines_content_view_new_file_for_student + ' ' + getTraductionTypeContent(content?.type)">
            </app-empty-data>

            <app-empty-data
                class="py-4 block"
                *ngIf="user.role_type !== 'student' && (content?.type === 'Assignment' || content?.type === 'UploadAssignment')"
                [text]="i18n.disciplines_content_view_upload_for_student + ' ' + getTraductionTypeContent(content?.type)">
            </app-empty-data>

            <!-- DOCS AND SHEETS -->
            <ng-container *ngIf="user.role_type === 'student' && (content?.type === 'DocsAssignment' || content?.type === 'SheetsAssignment')">
                <ng-container *ngIf="isLoadingUrl">
                <div class="flex justify-center items-center">
                    <span class="iconify text-customGray-default" style="width: 50px; height: 50px" data-icon="line-md:loading-twotone-loop"></span>
                </div>
                </ng-container>
                <div *ngIf="isMobile" class="flex flex-col justify-center items-center p-4">
                <span class="p-2 block font-medium text-customBlue-default text-center">
                    {{ i18n.disciplines_content_button_open }} {{ content?.type === 'DocsAssignment' ? i18n.disciplines_google_docs : i18n.disciplines_google_sheets }}
                </span>
                <a [href]="content?.userData?.file?.public_url | safeUrl" target="_blank"
                class="px-3 py-2 text-customBlue-default font-semibold border border-customBlue-default rounded">
                {{ i18n.disciplines_content_open_in }} {{ content?.type === 'DocsAssignment' ? i18n.disciplines_google_docs : i18n.disciplines_google_sheets }}
                </a>
                </div>
                <div *ngIf="!isLoadingUrl && !isMobile" class="flex justify-center p-2">
                <iframe
                    [src]="content?.userData?.file?.public_url | safeUrl"
                    width="100%"
                    [height]="content?.userData?.file?.mime_type === 'application/vnd.google-apps.spreadsheet' ? '800px' : '1200px'"
                ></iframe>
                </div>

            </ng-container>

            <!-- UPLOAD QUIZ -->
            <ng-container *ngIf="content?.type === 'UploadQuiz'">
                <app-content-view-upload-quiz
                [getDataFromContentView]="getDataFromContentView"
                [content]="content"
                ></app-content-view-upload-quiz>
            </ng-container>

            <!-- YOUTUBE -->
            <ng-container  *ngIf="content?.type === 'Youtube'">

                <div class="video-container ">
                    <iframe
                        [attr.data-mimetype]="'video/mp4'"
                        [src]="url | safeUrl"
                        frameborder="0"
                        allow="accelerometer; encrypted-media; gyroscope;"
                        allowfullscreen
                    ></iframe>

                </div>
            </ng-container>

            <!-- SCORM AND FILE -->
            <div *ngIf="hasIframe">
                <ng-container *ngIf="isLoadingUrl">
                <div class="flex justify-center items-center">
                    <span class="iconify text-customGray-default" style="width: 50px; height: 50px" data-icon="line-md:loading-twotone-loop"></span>
                </div>
                </ng-container>

                <ng-container *ngIf="errorScorm">
                <div class="flex flex-col justify-center items-center gap-2">
                    <span class="iconify w-12 h-12 text-customGray-default" data-icon="ri:alert-fill"></span>
                    <span class="text-customGray-dark text-22">{{ i18n.disciplines_warning_empty }}</span>
                </div>
                </ng-container>

                <iframe
                (load)="onLoad()"
                *ngIf="content?.type !== 'File' && content?.type !== 'UploadQuiz'"
                style="width: 100%; height: 100vh;"
                [src]="url | safeUrl"
                allowfullscreen
                ></iframe>

              <ng-container *ngIf="content?.type === 'File'">
                <ng-container *ngFor="let file of content.contentSpecific.files; let index = index;">
                    
                  <div class="mb-4">
                    <ng-container *ngIf="file.trashed">
                        <div class="text-center text-customBlue-default text-22 my-6">
                            {{ i18n.disciplines_file_sharing_warning_file_removed }}
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!file.trashed">
                        <div class="video-container ">
                            <iframe
                              width="100%" 
                              class="video"
                              [attr.data-mimetype]="file.mime_type"
                              [src]="file?.url | safeUrl"
                              scrolling="auto"
                              allowfullscreen
                            ></iframe>
                        </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
          </div>
        </div>


        <!-- Content Forum -->
        <ng-container *ngIf="content?.type === 'Forum' || content?.attached_forum?.items">
            <div class="p-2">
                <app-forum-detail
                    [isComponentForum]="true"
                    [forum_post]="content?.type === 'Forum' ? content?.contentSpecific : content?.attached_forum"
                ></app-forum-detail>

                    <app-forum-comment
                        [isComponentForum]="true"
                    ></app-forum-comment>
                </div>
            </ng-container>

        <!-- Support Files -->
        <div
          class="my-8 bg-customWhite-default border border-customBlue-glass rounded-md text-customBlue-default"
          *ngIf="content?.type === 'File'"
        >
          <header
            class="flex items-center justify-center gap-2 text-19 font-bold p-4"
            role="button"
            (click)="toggleCollapseOption()"
            [ngClass]="{ 'border-b border-customBlue-glass': collapseOption }"
          >
            <p>{{ i18n.disciplines_content_view_support_materials }}</p>
            <div
              class="transform transition-all"
              [ngClass]="{
                'rotate-90': !collapseOption,
                'rotate--90': collapseOption
              }"
            >
              <span
                class="iconify-inline text-16"
                data-icon="bxs:right-arrow"
              ></span>
            </div>
          </header>
          <div class="overflow-hidden">
            <div [@collapse]="collapseOption">
              <ul class="p-4 flex flex-col gap-4">
                <li
                  class="px-4 py-2 flex items-center justify-between gap-2 rounded shadow font-medium"
                  [ngClass]="{
                    'bg-customWhite-bWhite': i === indexFile
                  }"
                  *ngFor="
                    let file of content?.contentSpecific?.files;
                    let i = index
                  "
                >
                    <ng-container *ngIf="file.trashed">
                        <div></div>
                        <div class="flex justify-center items-center text-center text-customBlue-default text-16">
                            {{ i18n.disciplines_file_sharing_warning_file_removed }}
                        </div>
                        <div></div>
                    </ng-container>

                    <ng-container *ngIf="!file.trashed">
                        <button
                            type="button"
                            class="flex items-center gap-4 py-1"
                            (click)="changeFile(i)"
                        >
                            <app-content-types-icons
                            [type]="returnIconMaterialsSupport(file.mime_type)"
                            [color]="'#233674'"
                            ></app-content-types-icons>
                            <p>{{ file.label }}</p>
                        </button>
                        <button
                            type="button"
                            class="flex items-center gap-2 px-2 py-1 font-medium border-l border-customBlue-light"
                            (click)="getGoogleFile(file)"
                        >
                            <p>{{ i18n.disciplines_content_view_download }}</p>
                            <span
                            class="iconify-inline text-22"
                            data-icon="ic:sharp-cloud-download"
                            ></span>
                        </button>
                    </ng-container>
                </li>
              </ul>
            </div>
          </div>
        </div>


            <!-- Comments -->
            <div
            *ngIf="hasFeedBack"
            class="mt-8 bg-customWhite-default border border-customBlue-glass rounded-md text-customBlue-default"
            >
            <header
                class="flex items-center justify-center gap-2 text-19 font-bold p-4"
                role="button"
                (click)="collapseOptionComments = !collapseOptionComments;"
                [ngClass]="{ 'border-b border-customBlue-glass': collapseOptionComments }"
            >
                <p>{{ i18n.disciplines_comments }}</p>
                <div
                class="transform transition-all"
                [ngClass]="{
                    'rotate-90': !collapseOptionComments,
                    'rotate--90': collapseOptionComments
                }"
                >
                <span
                    class="iconify-inline text-16"
                    data-icon="bxs:right-arrow"
                ></span>
                </div>
            </header>
            <div class="overflow-hidden">
                <div
                [@collapse]="collapseOptionComments"
                >
                <div class="flex p-8">
                    <app-user-frame
                    class="new__user-frame"
                    [user_name]="feedback?.name"
                    [img_src]="feedback?.image"
                    ></app-user-frame>

                    <div class="flex flex-col gap-2 ml-5">

                    <div class="flex gap-2 items-center">
                        <span class="text-16 text-customGray-dark font-bold">
                        {{ feedback?.name }}
                        </span>

                        <span class="text-16 text-customGray-dark italic">
                        {{ i18n.disciplines_notices_published }} {{ getTime(feedback?.date) }}
                        </span>
                    </div>

                    <div class="text-16 text-customGray-dark font-normal">
                        {{ feedback?.text }}
                    </div>

                    </div>
                </div>
                </div>
            </div>
            </div>

        <!-- Student notification -->
        <div *ngIf="content?.completion_rule === 'STUDENT_NOTIFICATION' && content?.type !== 'UploadQuiz'" >
          <div class="flex w-full justify-end pr-1">
            <button *ngIf="isConcludedStudentNotification === 'PENDING'"  class="button-no-concluded"
              (click)="studentNotification()"
              [disabled]="!content?.delivery_allowed"
              [ngClass]="{'cursor-not-allowed': !content?.delivery_allowed}">
              {{ i18n.disciplines_mark_as_done }}
            </button>
            <button *ngIf="isConcludedStudentNotification === 'COMPLETE'"  class="button-concluded">
              {{ i18n.disciplines_concluded }}
            </button>
          </div>
        </div>

        </div>
        </ng-container>


        <ng-container *ngIf="isLoading">
            <div class="w-full">
                <app-content-view-skeleton
                [expanded]="expanded"
                ></app-content-view-skeleton>
            </div>
        </ng-container>

        <!-- ContentList -->
        <app-content-view-content-list
            #contentViewContentList
            [user]="user"
            [expanded]="expanded"
            [i18n]="i18n"
            [content_external_id]="content_external_id"
            [discipline_external_id]="discipline_external_id"
            [section_external_id]="section_external_id"
            [content]="content"
            (goToContentEmitter)="goToContentEmitter($event)"
            (getConditionalContentTitle)="getConditionalContent($event)"
        ></app-content-view-content-list>
    </div>
</section>
