import {
    AfterContentInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit,
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DisplayPanelComponent } from 'src/app/components/display-panel/display-panel.component';
import { UploadService } from 'src/app/components/upload/upload.service';
import { UploadInlineComponent } from 'src/app/components/upload-inline/upload-inline.component';
import { ContentSharedService } from 'src/app/pages/disciplines/components/content/content-shared.service';
import { LoginService } from 'src/app/pages/login/login.service';
import { ErrorReportingService } from 'src/app/services/errorReproting/error-reporting.service';
import { GoogleDrivePickerService } from 'src/app/services/google-drive-picker-service.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-upload-task-delivery',
    templateUrl: './upload-task-delivery.component.html',
    styleUrls: ['./upload-task-delivery.component.scss']
})
export class UploadTaskDeliveryComponent extends UploadInlineComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
    // inputs para configuração de visualização do componente upload

    @Input() delivery_allowed: boolean;
    @Input() statusDelivery: string;
    @Input() isStateSended: boolean;
    @Input() remainingAttempts: number;

    @Input() content: any;

    @ViewChild('displayPanelWarning') displayPanelWarning! : DisplayPanelComponent;

    constructor(
        public sharedService: SharedService,
        public googleDrivePickerService: GoogleDrivePickerService,
        public uploadService: UploadService,
        public platModalService: PlatformModalsService,
        public element: ElementRef,
        public route: ActivatedRoute,
        public contentSharedService: ContentSharedService,
        public localStorageService: LocalStorageService,
        public loginService: LoginService,
        public zone: NgZone,
        public cd: ChangeDetectorRef,
        public errorHandler: ErrorReportingService
    ) {
        super(
            sharedService,
            googleDrivePickerService,
            uploadService,
            platModalService,
            element,
            route,
            contentSharedService,
            localStorageService,
            loginService,
            zone,
            cd,
            errorHandler
        );
    }

    ngOnInit(): void {
        this.getTranslations();
        this.getProvider();
        this.getUser();
        this.getSyncWithDriveValue();
        this.localStorageService.checkCookiesEnabled();
        this.checkPickerTokenValidation();
        this.responseSubscriptionGoogleData();
    }

    getProvider() {
        this.provider = localStorage.getItem('provider') || null;

        if (this.provider === null) {
            this.getWhoami();
        }
    }

    getWhoami() {
        this.loginService.whoami().subscribe({
            next: (response) => {
                this.provider = response?.storage?.provider;
                localStorage.setItem('provider', response?.storage?.provider);
            },
            error: (err) => {
                this.platModalService.toggle('message', err.error.error, 'close');
            }
        });
    }

    isAfterEndDate():boolean {
        const currentDate = new Date();
        const endDate = new Date(this.content.end_date);
        if (currentDate > endDate) {
            return true;
        }
        return false;
    }

    checkStatusDelivery(): string {
        if (this.isStateSended) {
            if (
                this.statusDelivery === 'late_delivered'
            ) {
                return 'show_late_delivered_label';
            }

            if (
                (this.statusDelivery === 'on_time_delivered'
                || this.statusDelivery === 'open_to_deliveries')
            ) {
                return 'show_delivered_label';
            }
        }

        if (!this.isStateSended) {
            if (this.statusDelivery === 'late_delivered') {
                if (
                    this.remainingAttempts === 0
                ) {
                    return 'show_late_delivered_label';
                }
                return 'show_action_buttons';
            }

            if (
                this.statusDelivery === 'on_time_delivered'
                || this.statusDelivery === 'open_to_deliveries'
            ) {
                if (
                    this.remainingAttempts === 0
                ) {
                    return 'show_delivered_label';
                }
                return 'show_action_buttons';
            }
        }

        return 'show_action_buttons';
    }

    returnSubmissionAttempts(): string {
        const submissionsAttempts = this.remainingAttempts;

        if (submissionsAttempts > 1 || submissionsAttempts === 0) {
            return `${submissionsAttempts.toString()} ${this.i18n.upload_submission_attempts_remaining}`;
        }

        return `${submissionsAttempts.toString()} ${this.i18n.upload_submission_attempt_remaining}`;
    }

    getLocationToDisplayPanel($event, message: any) {
        const windowWidth = window.innerWidth;

        const top = $event.clientY + 10;
        const left = $event.clientX + 8;
        this.displayPanelObject.top = `${top}px`;
        this.displayPanelObject.left = `${left}px`;
        if (windowWidth < 1065) {
            this.displayPanelObject.left = '';
            this.displayPanelObject.right = '0px';
        }

        if (message) {
            this.textFlowMethodWarning = message;
            this.displayPanelWarning.toggle($event);
        }
    }
}
