import {
    Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-user-frame',
    templateUrl: './user-frame.component.html',
    styleUrls: ['./user-frame.component.scss']
})
export class UserFrameComponent implements OnDestroy, OnChanges {
    @Input() img_src: string;
    @Input() background_color: string;
    @Input() user_name: string;
    @Input() adaptable_size: boolean;
    @Input() style: any;
    @Input() class: string;

    // sets the custom breakpoint width to mobile
    @Input() breakpoint_X = '944px';

    initial_letter: string;
    img_alt: string;
    breakpointObserverKey: any;

    constructor(private element: ElementRef, private sharedService: SharedService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if ((changes.user_name && this.user_name) || (changes.img_src && this.img_src)) {
                this.getInitialLetter();
                this.getImageAlt();
                this.setBackgroundColor();

                this.isAdaptiveSize(this.adaptable_size);
            }

            this.checkInputClass();
        }
    }

    checkInputClass() {
        const $frame = this.element.nativeElement.querySelector('.frame');
        if (!this.class) {
            $frame.classList.add('default_img_size');
            return;
        }

        if (this.class && (this.class.includes('w-') || this.class.includes('h-'))) {
            $frame.classList.remove('default_img_size');
            return;
        }

        if (this.class && (!this.class.includes('w-') || !this.class.includes('h-'))) {
            $frame.classList.add('default_img_size');
        }
    }

    isAdaptiveSize(adaptable_size) {
        if (adaptable_size) {
            this.calculateFontSize();
            return;
        }

        this.getMediaQuerySubscription();
    }

    getInitialLetter() {
        const userName = this.user_name.repeat(1);
        this.initial_letter = userName.charAt(0).toUpperCase();
    }

    getImageAlt() {
        if (this.user_name) {
            this.img_alt = `Imagem de perfil de ${this.user_name}`;
            return;
        }
        this.img_alt = 'Imagem de perfil';
    }

    setBackgroundColor() {
        const $frame = this.element.nativeElement.querySelector('.frame');

        if (this.background_color) {
            $frame.style.backgroundColor = this.background_color;
            return;
        }
        $frame.style.backgroundColor = 'CornflowerBlue';
    }

    getMediaQuerySubscription() {
        this.breakpointObserverKey = this.sharedService.callFunctionsOnBreakpoint(this.breakpoint_X, {
            element: this.element,
            queryString: '.frame'
        });
    }

    calculateFontSize() {
        const $frame = this.element.nativeElement.querySelector('.frame');
        $frame.classList.add('adaptable');
        const frameSize = $frame.offsetWidth;
        const constantWidth = 40;
        const constantFontSize = 23;

        const fontSize = (frameSize * constantFontSize) / constantWidth;
        $frame.style.fontSize = `${fontSize}px`;
    }

    onResize($event) {
        $event.stopPropagation();

        if (this.adaptable_size) this.calculateFontSize();
    }

    onImageError() {
        this.img_src = '';
    }

    ngOnDestroy() {
        this.sharedService.removeBreakpointObserver(this.breakpointObserverKey);
    }
}
